import React from 'react'
import {Header} from 'semantic-ui-react'
const AboutSplash = () => 
    <div style={{padding: "10vh"}}>
        <Header  style={{fontSize: '5vh'}}>
            About Pictor.
        </Header>
        <p style={{color: '', fontSize: '3vh'}}>
            Redefining histopathology.
        </p>
    </div>

export default AboutSplash