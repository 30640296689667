import React from "react";
import {Header, Image, Grid, Transition, Card} from 'semantic-ui-react'
// IMAGES
import HE from '../../Assets/Stains/restainhe.png'
import PAS from '../../Assets/Stains/restainpas.png'
import MT from '../../Assets/Stains/restainmt.png'
import JONES from '../../Assets/Stains/restainjones.png'
import PASthumb from '../../Assets/Stains/PASthumb.png'
import MTthumb from '../../Assets/Stains/MTthumb.png'
import JONESthumb from '../../Assets/Stains/JONESthumb.png'
import Magnifier from "react-magnifier";

class InteractiveRS extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
            welcome: false, 
            pick: false, 
            stain: false, 
            inputtype: undefined,
            staintype: undefined
        }
        this.handleOn()
    }
    handleClose = () => {
        this.setState({
            inputtype: undefined,
            staintype: undefined,
            pick: false, 
            stain: false })
    }

    handleOn = () => {
        setTimeout(() => {
            this.setState({welcome: true})
        }, 100);
        setTimeout(() => {
            this.setState({welcome: false})
        }, 3000);
        setTimeout(() => {
            this.setState({pick: true, inputtype: HE})
        }, 5000);
    }
    handleInteractiveInput = (input) => {
        this.setState({inputtype: input})
    }
    handleInteractiveStain = (stain) => {
        this.setState({stain: true, staintype: stain})
    }

    render () {
      const { welcome, pick, stain, staintype, inputtype } = this.state;
      return (
            <div style={{padding: '7vh'}}>
                {/* WELCOME TRANSITION */}
                <Transition visible={welcome} 
                            animation='scale' 
                            duration={1500}>
                    <Header style={{fontSize: '7vh'}} inverted>
                        Welcome to the Pictor Interactive Experience.
                    </Header>
                </Transition>  
                <Grid className="centeredButton">
                    {/* SELECT STAIN */}
                    <Grid.Row>
                        <Transition 
                            visible={pick} 
                            animation='scale' 
                            duration={1500}>
                            <div>
                                <p inverted style={{fontSize: '4vh'}}>
                                    Pick an output stain to begin.
                                </p>
                                <Grid columns={3}>                          
                                    <Grid.Column>
                                        <Image as={Card} 
                                            onClick={() => {this.handleInteractiveStain(JONES)}}
                                            disabled={staintype !== JONES} 
                                            circular 
                                            centered
                                            size="tiny" 
                                            src={JONESthumb}/>
                                        <p style={{textAlign: 'center'}}>Jones</p>

                                    </Grid.Column>
                                    <Grid.Column>
                                        <Image as={Card} 
                                            onClick={() => {this.handleInteractiveStain(PAS)}}  
                                            disabled={staintype !== PAS} 
                                            centered
                                            circular 
                                            size="tiny" 
                                            src={PASthumb}/>
                                        <p style={{textAlign: 'center'}}>PAS</p>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Image as={Card} 
                                            onClick={() => {this.handleInteractiveStain(MT)}}
                                            disabled={staintype !== MT} 
                                            circular size="tiny" centered src={MTthumb}/>
                                        <p style={{textAlign: 'center'}}>Masson's Trichrome</p>
                                    </Grid.Column>
                                </Grid>
                            </div>
                        </Transition>  
                    </Grid.Row>
                    {/* MAGNIFIER */}
                    <Grid.Row>
                        <Transition duration={3000} visible={stain}>
                            <div className="centeredButton">
                                <Magnifier mgTouchOffsetX={0} 
                                           mgTouchOffsetY={0} 
                                           zoomFactor={1} 
                                           width="35vh" 
                                           src={inputtype} 
                                           zoomImgSrc={staintype}/>
                                <Header inverted>Hover over the <i>labelled</i> tissue image to virtually re-stain it.</Header>
                            </div>
                        </Transition>
                    </Grid.Row>
                </Grid>
            </div>
      );
    }
}
export default InteractiveRS;
