import React, { Component } from 'react'
import {Image} from 'semantic-ui-react'
import logo_colored from '../Assets/Logo/pictorlabs_colored.png'
export default class PictorFooter extends Component {
    render() {  
      return (
        <div style={{backgroundColor: 'whitesmoke'}}>
        <div style={{padding: 20}}>
            <Image centered size="small" src={logo_colored}/>
        </div>
        </div>
      )
    }
}
  