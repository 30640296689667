import React from "react";
import {Image, Header, Divider, 
        Grid, Button, Transition, Container} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
// CUSTOM COMPONENTS
import Menu from '../Components/Menu'
import Footer from '../Components/Footer'
import ProductQualities from '../Components/ProductQualities'
import {isMobile} from 'react-device-detect';

// IMAGES
import logo_colored from '../Assets/Logo/pictorlabs_colored.png'
import aifriendly from '../Assets/Miscellaneous/aifriendly.png'
import BWStain from '../Assets/Miscellaneous/bwstain.png'
import ColoredStainOne from '../Assets/Miscellaneous/coloredstain1.png'
import ColoredStainTwo from '../Assets/Miscellaneous/coloredstain2.png'
import ColoredStainThree from '../Assets/Miscellaneous/coloredstain3.png'
import MinimalStains from '../Assets/Miscellaneous/minimalstains.png'

const stains = [ColoredStainOne, ColoredStainTwo, ColoredStainThree]
class App extends React.Component {
    constructor(props) {
      super(props)
      this.state = { scrolling: false, 
                     vstransition: false, 
                     vsstain: ColoredStainOne, 
                     staincounter: 0}
    }

    componentDidMount(){
      setInterval(() => {
        const {vstransition, staincounter} = this.state;
        this.setState({vstransition: !vstransition})
        if (!vstransition){
          this.setState({vsstain: stains[staincounter % 3]})
          if (staincounter === 2){
            this.setState({staincounter: 0})
          }
          else {
            this.setState({staincounter: staincounter + 1})
          }
        }
      }, 1800);
    }

    render () {
      const {vstransition, vsstain} = this.state;
      return (
        <div>
          {/* HOME -> SPLASH MOVING GRADIENT */}
          <div fluid className="gradientbk">
            <Menu inverted activeItem="home" style={{position: 'relative'}}/>
            <div className="App-header">
                <Image centered size="massive" src={logo_colored}/>
                <p style={{color:'white', padding: '2vh'}}>Redefining Histopathology.</p>
            </div>
          </div>
          {/* Tech Info */}
          <div>
            <div style={{padding: '7vh'}}>
              <Grid columns={3}>
                <Grid.Column width={8}>
                  <div className="bo">
                    <Header style={{fontSize: '5vh'}}>
                      Virtual histological staining of label-free tissue.
                    </Header>
                    <p style={{fontSize: '3vh'}}>Autofluorescence to virtual histochemical labeling.</p>
                  </div>
                </Grid.Column>
                <Grid.Column width={4}>
                <Transition visible={!vstransition} animation='scale' duration={500}>
                        <Image centered size="big" src={BWStain} />
                </Transition> 
                </Grid.Column>
                <Grid.Column width={4}>
                    <Transition visible={vstransition} animation='scale' duration={500}>
                        <Image centered size="big" src={vsstain} />
                    </Transition> 
                </Grid.Column>

              </Grid>
            </div> 
            <Divider/>
            <div style={{padding: '5vh'}}>
              <Grid columns={2}>
                <Grid.Column>
                  <div style={{padding: '2vh'}}>
                    <Image size="big" src={MinimalStains} />
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <Header style={{fontSize: '5vh'}}>
                  Virtual re-staining of tissue images. 
                </Header>
                <p style={{fontSize: '3vh'}}>Stain to stain transformations.</p>
                </Grid.Column>
              </Grid>
            </div> 
            <div style={{padding: 30}}>
                
            </div> 
            <Divider/>
            <ProductQualities/>
            <Divider/>
            { !isMobile && <div style={{padding: '10vh', minHeight: '10vh'}}>
              <Grid columns={2}>
                <Grid.Column>
                  <Container>
                    <Header className="vertical-center" style={{fontSize: '5vh'}}>
                      Our tech is symbiotic with all AI based histopathology. 
                    </Header>
                  </Container>
                </Grid.Column>
                <Grid.Column>
                  <div>
                    <Image centered size="massive" src={aifriendly} />
                  </div>
                </Grid.Column>
              </Grid>
            </div> }
            { isMobile && <div style={{padding: '3vh', minHeight: '10vh'}}>
              <div>
              <Header style={{fontSize: '5vh'}}>
                      Our tech is symbiotic with all AI based histopathology. 
                    </Header>
                    <Image centered size="massive" src={aifriendly} />
              </div>
            </div> }
           
            <div className="gradientbk">
                <div style={{padding: '10vh'}}>
                  <Header inverted style={{textAlign: 'center', fontSize: 40}}>
                    Interested in joining our team?
                  </Header>

                  <p style={{color: 'white', textAlign: 'center', fontSize: 30}}>We're hiring!</p>
                  <div className="centeredButton">
                    <Link to="/careers" >
                    <Button size="massive" style={{color: 'white', backgroundColor: '#eb2c80'}} >Apply</Button>
                    </Link>
                  </div>
                </div>
            </div> 
          </div>
          <Footer/>
        </div>
      );
    }
}
export default App;
