import React from "react";
import {Header, Grid, Image} from 'semantic-ui-react'

// CUSTOM COMPONENTS
import Menu from './Menu'
import Footer from './Footer'
import EmailPic from '../Assets/Miscellaneous/email.png'

class ContactUs extends React.Component {
    constructor(props) {
      super(props)
      this.state = { scrolling: false }
    }
    render () {
      return (
        <div>
          <div className="deepstainbk">
            <Menu stackable activeItem="contact us" 
                  style={{backgroundColor:'whitesmoke', 
                          position: 'relative'}}/>
            <div style={{padding: '10vh'}}>
                  <Header  style={{fontSize: '5vh'}}>Contact Us</Header>
                  <p style={{color: 'black', fontSize: '3vh'}}>
                  We'd love to hear from you.</p>
            </div>
            <div style={{backgroundColor: 'whitesmoke', padding: '5vh'}}>
                <Grid columns={5}>
                  <Grid.Row>
                  <Image size="small" src={EmailPic}/>
                  </Grid.Row>
                  <Grid.Row>
                    <div style={{width: "100%"}}>
                    <iframe title="pictormap" width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=11390%20West%20Olympic%20Boulevard,%20Los%20Angeles,%20CA+(Pictor%20Labs)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">measure distance on map</a></iframe>
                    </div>
                  </Grid.Row>
                </Grid>
            </div>
          </div>
          <Footer/>
        </div>
      );
    }
}
export default ContactUs;
