import React from "react";
import {Header} from 'semantic-ui-react'

// CUSTOM COMPONENTS
import Menu from './Menu'
import Footer from './Footer'
import PublicationsTimeline from './Timeline/Timeline'
const Pubs = () => (
    <PublicationsTimeline/>
  )
class Publications extends React.Component {
    constructor(props) {
      super(props)
      this.state = { scrolling: false }
    }
    render () {
      return (
        <div>
          {/* HOME -> SPLASH MOVING GRADIENT */}
          <div fluid className="restainbk">
            <Menu activeItem="publications" 
                  style={{backgroundColor:'whitesmoke', position: 'relative'}}/>
            <div style={{padding: '10vh'}}>
                <Header style={{fontSize: '5vh'}}>News <span>&#38;</span> Publications</Header>
                <p style={{color: 'black', fontSize: '3vh'}}>The science behind Pictor Labs.</p>
               <div>
                <Pubs/>
               </div> 
            </div>
          </div>
          <Footer/>
        </div>
      );
    }
}
export default Publications;
