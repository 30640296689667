import React from "react";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import {Header} from 'semantic-ui-react'
import 'react-vertical-timeline-component/style.min.css';


class Publications extends React.Component {
    constructor(props) {
      super(props)
      this.state = { scrolling: false }
    }
    render () {
      return (
        <VerticalTimeline>
          <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#5706BF', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid' }}
              iconStyle={{ background: '#5706BF', color: '#fff' }}
          >
              <Header inverted>
                AACR: Pictorlabs showcases virtual multiplex special/IHC stains for augmented staining capabilities
              </Header>
              <p><a style={{color: 'white'}} className="pubhover" href="https://websitev0.s3.us-west-1.amazonaws.com/2024_PictorLabs_AACR_final%20-%20with%20abstract%20number.pdf">PDF</a></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#5706BF', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid' }}
              iconStyle={{ background: '#5706BF', color: '#fff' }}
          >
              <Header inverted>
                USCAP: Multi-institutional collaboration between Pictor Labs, University of Maryland, Johns Hopkins, and UCLA pathology departments for first blinded study comparing AI computational H&E staining to chemical H&E for lymphoma diagnosis.
              </Header>
              <p><a style={{color: 'white'}} className="pubhover" href="https://websitev0.s3.us-west-1.amazonaws.com/Pictor+and+Univ+Maryland+USCAP+poster+-+March+2024+(3).pdf">PDF</a></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#5706BF', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid' }}
              iconStyle={{ background: '#5706BF', color: '#fff' }}
          >
              <Header inverted>
                  Conventional staining of post-mortem samples can be affected by several factors, including tissue autolysis. Here, the authors demonstrate a virtual staining tool using a...
              </Header>
              <p><a style={{color: 'white'}} className="pubhover" href="https://www.nature.com/articles/s41467-024-46077-2">Nature Communications (2024)</a> | 
              <a style={{color: 'white'}} className="pubhover" href="https://www.nature.com/articles/s41467-024-46077-2.pdf">PDF</a></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: '#5706BF', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid' }}
              iconStyle={{ background: '#5706BF', color: '#fff' }}
          >
              <Header inverted>
                  SITC: Pictorlabs showcases computational overlay combining virtual IHC and H&E stains atop chemical PD-L1 to augment pathologist PD-L1 scoring and improve accuracy around diagnostic cut-offs in NSCLC
              </Header>
              <p>
                  <a style={{color: 'white'}} className="pubhover" href="https://cdn.pictorlabs.ai/website/2023_PictorLabs_SITC_Portrait_22.5x33_vFF.pdf">PDF</a>
              </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#5706BF', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid' }}
            iconStyle={{ background: '#5706BF', color: '#fff' }}
          >
            <Header 
             inverted>
                  Charles River Laboratories collaborates with Pictorlabs to develop cutting edge multi-organ virtual staining methodology for production use with goal of replacing entire chemical and glass slide workflow</Header>
              <p>
                <a style={{color: 'white'}} className="pubhover" href="https://www.sciencedirect.com/science/article/pii/S1359644623002635">ScienceDirect (2023)</a></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#5706BF', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid' }}
            iconStyle={{ background: '#5706BF', color: '#fff' }}
          >
            <Header 
             inverted>
                  Pictorlabs and University of Maryland School of Medicine present virtual immune phenotyping proof of concept stains for lymphoma at ASCO Breakthrough 2023</Header>
              <p>
                <a style={{color: 'white'}} className="pubhover" href="https://websitev0.s3.us-west-1.amazonaws.com/2023_PictorLabs%20Poster%20Template_30x22.5_complete_PDF.pdf">PDF</a></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#5706BF', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid' }}
            iconStyle={{ background: '#5706BF', color: '#fff' }}
          >
            <Header 
             inverted>
                  Pictorlabs presents findings alongside NanoString and University of Maryland School of Medicine combining virtual staining and spatial transcriptomics at AACR 2023</Header>
              <p>
                <a style={{color: 'white'}} className="pubhover" href="https://websitev0.s3.us-west-1.amazonaws.com/2023_NanoString_PictorLabs_AACR.pdf">PDF</a></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#5706BF', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid' }}
            iconStyle={{ background: '#5706BF', color: '#fff' }}
          >
            <Header 
             inverted>
                  Pictorlabs Launches with More Than $18.8 Million to Advance Transformative Virtual
Staining</Header>
              <p>
                <a style={{color: 'white'}} className="pubhover" href="https://websitev0.s3.us-west-1.amazonaws.com/PictorLabs-PressRelease-12.01.22.pdf">PDF</a></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#5706BF', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid' }}
            iconStyle={{ background: '#5706BF', color: '#fff' }}
          >
            {/* <Image size="tiny" floated="right" src={PubFour}/> */}
            <Header 
             inverted>
                  Label-free virtual HER2 immunohistochemical staining of breast tissue using deep learning</Header>
              <p><a className="pubhover" style={{color: 'white'}} href="https://spj.science.org/doi/full/10.34133/2022/9786242">
              BMEF</a> |
                <a style={{color: 'white'}} className="pubhover" href="https://spj.science.org/doi/epdf/10.34133/2022/9786242">PDF</a></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#5706BF', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid' }}
            iconStyle={{ background: '#5706BF', color: '#fff' }}
          >
            {/* <Image size="tiny" floated="right" src={PubFour}/> */}
            <Header 
             inverted>
                  Deep Learning-Enabled Virtual H&E and Fluoro-Jade B Tissue Staining for Neuronal Degeneration <br/>(In collaboration with Charles River Labs)</Header>
              <p><a className="pubhover" style={{color: 'white'}} href="https://websitev0.s3.us-west-1.amazonaws.com/path2021.pdf">
              Society of Toxicologic Pathology 40th Annual Symposium, Baltimore, MD (2021)</a> | 
                <a style={{color: 'white'}} className="pubhover" href="https://websitev0.s3.us-west-1.amazonaws.com/path2021.pdf">PDF</a></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#5706BF', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid' }}
            iconStyle={{ background: '#5706BF', color: '#fff' }}
          >
            {/* <Image size="tiny" floated="right" src={PubTwo}/> */}
            <Header
                inverted>Deep learning-based transformation of H&E stained tissues into special stains</Header>
            <p><a style={{color: 'white'}} className="pubhover" href="https://www.nature.com/articles/s41467-021-25221-2">Nature Communications (2021)</a> | 
            <a style={{color: 'white'}} className="pubhover" href="https://www.nature.com/articles/s41467-021-25221-2.pdf">PDF</a></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#5706BF', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid' }}
            iconStyle={{ background: '#5706BF', color: '#fff' }}
          >
            {/* <Image size="tiny" floated="right" src={PubOne}/> */}
            <Header 
             inverted>
                  Emerging Advances to Transform Histopathology Using Virtual Staining</Header>
              <p><a className="pubhover" style={{color: 'white'}} href="https://spj.sciencemag.org/journals/bmef/2020/9647163/?fbclid=IwAR3uE_aeLnopwxYwyjQS5Qfi_K8VOq49TkpPPa15JKaviEflFUKcm5uMoSk">
                BME Frontiers (2020)</a> | 
                <a style={{color: 'white'}} className="pubhover" href="https://websitev0.s3-us-west-1.amazonaws.com/9647163.pdf">PDF</a></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#5706BF', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid' }}
            iconStyle={{ background: '#5706BF', color: '#fff' }}
          >
            {/* <Image size="tiny" floated="right" src={PubThree}/> */}
            <Header 
            inverted> Digital synthesis of histological stains using micro-structured and 
              multiplexed virtual staining of label-free tissue</Header>
            <p><a className="pubhover" href="https://www.nature.com/articles/s41377-020-0315-y" style={{color: 'white'}}>
              Light: Science and Applications (2020)</a> | 
              <a className="pubhover" href="https://websitev0.s3-us-west-1.amazonaws.com/s41377-020-0315-y.pdf" style={{color: 'white'}}>PDF</a></p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: '#5706BF', color: '#fff' }}
            contentArrowStyle={{ borderRight: '7px solid' }}
            iconStyle={{ background: '#5706BF', color: '#fff' }}
          >
            {/* <Image size="tiny" floated="right" src={PubFour}/> */}
            <Header
             inverted>
              Virtual histological staining of <br/> 
              unlabelled tissue autofluorescence images via deep learning
            </Header>
            <p>
              <a style={{color: 'white'}} className="pubhover" href="https://www.nature.com/articles/s41551-019-0362-y.pdf?origin=ppub"> 
                Nature Biomedical Engineering (2019)</a> 
                | 
                <a style={{color: 'white'}} className="pubhover" href="https://websitev0.s3-us-west-1.amazonaws.com/s41551-019-0362-y.pdf">PDF</a></p>
          </VerticalTimelineElement>
          
        </VerticalTimeline>
      );
    }
}
export default Publications;
