import React, { Component } from 'react'
import { Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
export default class PictorMenu extends Component {
    state = { activeItem: this.props.activeItem }
    handleItemClick = (e, { name }) => this.setState({ activeItem: name })
    render() {
      const { activeItem } = this.state;
      return (
        <div style={{padding: 20}}>
            <Menu inverted={this.props.inverted} stackable size="massive" secondary>
            <Link to="/">
                <Menu.Item
                    name='home'
                    active={activeItem === 'home'}
                    onClick={this.handleItemClick}
                />
            </Link>
            {/* <Link to="/technologies">
            <Menu.Item
                name='technologies'
                active={activeItem === 'technologies'}
                onClick={this.handleItemClick}
            />
            </Link> */}
            <Link to="/publications">
            <Menu.Item
                name= {`News And Publications`}
                active={activeItem === 'publications'}
                onClick={this.handleItemClick}
            />
            </Link>
            <Link to="/about">
            <Menu.Item
                name='about'
                active={activeItem === 'about'}
                onClick={this.handleItemClick}
            />
            </Link>
            <Link to="/careers">
            <Menu.Item
                name='careers'
                active={activeItem === 'careers'}
                onClick={this.handleItemClick}
            />
            </Link>
            <Link to="/contact">
            <Menu.Item
                name='contact us'
                active={activeItem === 'contact us'}
                onClick={this.handleItemClick}
            />
            </Link>
            </Menu>
        </div>
      )
    }
}
  