import React from "react";
import {Header, Image} from 'semantic-ui-react'
// CUSTOM COMPONENTS
import Menu from './Menu'
import Footer from './Footer'
import EmailPic from '../Assets/Miscellaneous/email.png'
import LinkedIn from '../Assets/Miscellaneous/linkedin.png'


class Products extends React.Component {
    constructor(props) {
      super(props)
      this.state = { scrolling: false }
    }
    render () {
      return (
        <div>
          {/* HOME -> SPLASH MOVING GRADIENT */}
            <div fluid className="wipbk">
                <Menu stackable activeItem="careers" 
                      style={{backgroundColor:'whitesmoke', 
                              position: 'relative'}}/>
                <div style={{padding: '10vh'}}>
                  <Header style={{fontSize: '5vh'}}>Careers at Pictor.</Header>
                  <p style={{color: '', fontSize: '3vh'}}>
                  This is where the revolution starts.</p>
                </div>
                <div style={{backgroundColor: 'whitesmoke', padding: '2vh', minHeight: '60vh'}} className="centeredButton">
                    <Header style={{color: 'black', textAlign: 'center',fontSize: '4vh'}}>Interested in being part of our team? </Header>
                    <div style={{padding: '2vh'}}>
                      <Image as="a" href="https://www.linkedin.com/company/pictor-labs-inc" size="mini" src={LinkedIn}/>
                    </div>
                    <p style={{fontSize: '3vh', textAlign: 'center'}}>Send an email (resumé and a short paragraph of why you'd like to work with us) to: </p> 
                    <Image size="medium" src={EmailPic}/>
                </div>
            </div>
            
          <Footer/>
        </div>
      );
    }
}
export default Products;
