import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
// CSS
import './App.css';
import 'semantic-ui-css/semantic.min.css'
import Home from './Components/Home'
import Technologies from './Components/Technologies'
import Publications from './Components/Publications'
import Contact from './Components/Contact'
import Careers from './Components/Careers'
import AboutUs from './Components/About'
export default function App() {
  return (
    <Router>
      <div style={{overflowX: 'hidden'}}>
        <Switch>
          {/* <Route path="/technologies">
            <Technologies/>
          </Route> */}
          <Route path="/publications">
            <Publications/>
          </Route>
          <Route path="/about">
            <AboutUs/>
          </Route>
          <Route path="/contact">
            <Contact/>
          </Route>
          <Route path="/careers">
            <Careers/>
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
