import React from "react";
import {Header, Image, Grid, Transition, Card} from 'semantic-ui-react'
// IMAGES
import Input from '../../Assets/Stains/INPUT.png'
import HE from '../../Assets/Stains/HE.png'
import PAS from '../../Assets/Stains/PAS.png'
import MT from '../../Assets/Stains/MT.png'
import JONES from '../../Assets/Stains/JONES.png'
import HEthumb from '../../Assets/Stains/HEthumb.png'
import PASthumb from '../../Assets/Stains/PASthumb.png'
import MTthumb from '../../Assets/Stains/MTthumb.png'
import JONESthumb from '../../Assets/Stains/JONESthumb.png'
import Magnifier from "react-magnifier";

class InteractiveVS extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
            welcome: false, 
            pick: false, 
            stain: false, 
            staintype: undefined
        }
        this.handleOn()
    }
    handleClose = () => {
        this.setState({
            staintype: undefined,
            pick: false, 
            stain: false })
    }

    handleOn = () => {
        setTimeout(() => {
            this.setState({welcome: true})
        }, 100);
        setTimeout(() => {
            this.setState({welcome: false})
        }, 3000);
        setTimeout(() => {
            this.setState({pick: true})
        }, 5000);
    }

    handleInteractiveStain = (stain) => {
        this.setState({stain: true, staintype: stain})
    }

    render () {
      const { welcome, pick, stain, staintype } = this.state;
      return (
            <div style={{padding: '7vh'}}>
                {/* WELCOME TRANSITION */}
                <Transition visible={welcome} 
                            animation='scale' 
                            duration={1500}>
                    <Header style={{fontSize: '7vh'}} inverted>
                        Welcome to the Pictor Interactive Experience.
                    </Header>
                </Transition>  
                <Grid className="centeredButton">
                    {/* SELECT STAIN */}
                    <Grid.Row>
                        <Transition 
                            visible={pick} 
                            animation='scale' 
                            duration={1500}>
                            <div>
                                <p inverted style={{fontSize: '3vh'}}>
                                    Pick a stain to begin.
                                </p>
                                <Grid columns={4}>
                                    <Grid.Column>
                                        <Image as={Card} onClick={() => {this.handleInteractiveStain(HE)}} 
                                            circular 
                                            centered
                                            disabled={staintype !== HE}
                                            size="tiny" 
                                            src={HEthumb}/>
                                        <p style={{textAlign: 'center'}}>H&E</p>
                                    </Grid.Column>                           
                                    <Grid.Column>
                                        <Image as={Card} 
                                            onClick={() => {this.handleInteractiveStain(JONES)}}
                                            disabled={staintype !== JONES} 
                                            circular 
                                            size="tiny" 
                                            centered src={JONESthumb}/>
                                        <p style={{textAlign: 'center'}}>Jones</p>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Image as={Card} 
                                            onClick={() => {this.handleInteractiveStain(PAS)}}  
                                            disabled={staintype !== PAS} 
                                            circular 
                                            size="tiny" 
                                            centered src={PASthumb}/>
                                        <p style={{textAlign: 'center'}}>PAS</p>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Image as={Card} 
                                            onClick={() => {this.handleInteractiveStain(MT)}}
                                            disabled={staintype !== MT} 
                                            centered circular size="tiny" src={MTthumb}/>
                                        <p style={{textAlign: 'center'}}>Masson's Trichrome</p>
                                    </Grid.Column>
                                </Grid>
                            </div>
                        </Transition>  
                    </Grid.Row>
                    {/* MAGNIFIER */}
                    <Grid.Row>
                        <Transition duration={3000} visible={stain}>
                            <div className="centeredButton">
                                <Magnifier mgTouchOffsetX={0} 
                                           mgTouchOffsetY={0} 
                                           zoomFactor={1} 
                                           width="35vh" 
                                           src={Input} 
                                           zoomImgSrc={staintype}/>
                                <Header inverted>Hover over the <i>label free</i> tissue autofluorescence image to virtually stain the tissue.</Header>
                            </div>
                        </Transition>
                    </Grid.Row>
                </Grid>
            </div>
      );
    }
}
export default InteractiveVS;
