import React from "react";
import {Header, Button, Image, Grid, Dimmer} from 'semantic-ui-react'

// CUSTOM COMPONENTS
import Menu from './Menu'
import Footer from './Footer'
// IMAGES
import deepstain_logo from '../Assets/Technologies/deepstainlogodiagonal.png'
import restain_logo from '../Assets/Technologies/restainlogodiagonal.png'

import InteractiveVS from './Technologies/InteractiveVS'
import InteractiveRS from './Technologies/InteractiveRS'
class Technologies extends React.Component {
    constructor(props) {
      super(props)
      this.state = {interactivevs: false, interactivers: false}
    }
    render () {
      const { interactivevs, interactivers } = this.state;
      return (
        <div>
            {interactivevs && 
                <Dimmer style={{padding: '2vh'}} 
                    active
                    onClickOutside={() => {this.setState({interactivevs: false})}} 
                    page>
                <InteractiveVS/>
                <Button inverted icon="x" onClick={() => {this.setState({interactivevs: false})}}></Button>
                </Dimmer>
            }
            {interactivers && 
                <Dimmer style={{padding: '2vh'}} 
                    active
                    onClickOutside={() => {this.setState({interactivers: false})}} 
                    page>
                <InteractiveRS/>
                <Button inverted icon="x" onClick={() => {this.setState({interactivers: false})}}></Button>
                </Dimmer>
            }
          {/* HOME -> SPLASH MOVING GRADIENT */}
          <div fluid className="deepstainbk">
            <Menu activeItem="technologies" style={{backgroundColor:'whitesmoke', position: 'relative'}}/>
            <div style={{minHeight: '100vh',  padding: '5vh', }}>
                <Grid style={{paddingTop: '25vh'}} columns={2}>
                    <Grid.Column width={12}>
                        <div style={{padding: 20}}>
                            <Header style={{fontSize: '6vh'}}>Virtual histological staining of label-free tissue.</Header>
                            <p style={{fontSize: '3vh', color:'black'}}>Autofluorescence to virtual histochemical labeling.</p>
                            <Button 
                                    floated="left" 
                                    size="massive"
                                    onClick={() => {this.setState({interactivevs: true})}}>
                                    Get a sneak peek
                            </Button>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <div className="centeredButton">
                            <Image size="large" src={deepstain_logo}/>
                        </div>
                    </Grid.Column>
                </Grid>
            </div>
            </div>
            <div fluid className="restainbk">
            <div style={{minHeight: '100vh', padding: '10vh'}}>
                <Grid style={{paddingTop: '12vh'}} columns={2}>
                    <Grid.Column width={4}>
                        <div className="centeredButton">
                            <Image size="large" src={restain_logo}/>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <div style={{padding: '2vh'}}>
                            <Header style={{fontSize: '6vh'}}>Virtual re-staining of tissue images.</Header>
                            <p style={{color:'black', fontSize: '3vh'}}>Stain to stain transformations.</p>
                            <div>
                            <Button onClick={() => {this.setState({interactivers: true})}}  floated="left" size="massive">Get a sneak peek</Button>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid>
            </div>
            </div>
          <Footer/>
        </div>
      );
    }
}
export default Technologies;
