import React from 'react'
import {Grid, Image, Segment, Header} from 'semantic-ui-react'
import circle_redgrad from '../Assets/Miscellaneous/redgrad.png'
import circle_pinkgrad from '../Assets/Miscellaneous/pinkgrad.png'
import circle_bluegrad from '../Assets/Miscellaneous/bluegrad.png'

const ProductQualities = () => { return <div style={{padding: 30}}>
    <Grid columns={3}>
        <Grid.Column>
        <Segment vertical>
        <Image centered size="mini" src={circle_redgrad}/>
        <Header style={{fontSize: '3vh', textAlign: 'center'}}>Fast</Header>
        <p style={{fontSize: '2vh', textAlign: 'center'}}>
            Traditional staining requires hours and sometimes days to complete. <br/>
            Our tech <b>stains slides in minutes</b>. 
        </p>
        </Segment>
        </Grid.Column>
        <Grid.Column>
        <Segment vertical>
            <Image centered size="mini" src={circle_pinkgrad}/>
            <Header style={{fontSize: '3vh', textAlign: 'center'}}>Repeatable</Header>
            <p style={{fontSize: '2vh', textAlign: 'center'}}>
                Our tech minimizes stain variability <br/>
                by <b>standardizing the stain quality</b>. 
            </p>
        </Segment>
        </Grid.Column>
        <Grid.Column>
            <Segment vertical>
                <Image centered size="mini" src={circle_bluegrad}/>
                <Header style={{fontSize: '3vh', textAlign: 'center'}}>Preserves Tissue</Header>
                <p style={{fontSize: '2vh', textAlign: 'center'}}>
                    <b>Unlimited</b> number of stains <br/>from just <b>one sample</b>. 
                </p>
            </Segment>
        </Grid.Column>
    </Grid>
</div>}
export default ProductQualities