import React from "react";

// CUSTOM COMPONENTS
import Menu from './Menu'
import Footer from './Footer'
import AboutMain from '../Components/About/AboutComponents'
import AboutSplash from '../Components/About/Splash'
class About extends React.Component {
    constructor(props) {
      super(props)
      this.state = { scrolling: false }
    }
    render () {
      return (
        <div>
          {/* HOME -> SPLASH MOVING GRADIENT */}
          <div fluid className="pubsbk">
            <Menu stackable activeItem="about" 
                  style={{backgroundColor:'whitesmoke', position: 'relative'}}/>
            <AboutSplash/>
            <AboutMain/>
          </div>
        <Footer/>
        </div>
      );
    }
}
export default About;
